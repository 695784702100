import React from 'react';
import Layout from 'components/layout';
import SEO from "components/seo";
import { useStaticQuery, graphql } from 'gatsby';

import Section from 'sections/section';

const GetCinder = () => {

    const data = useStaticQuery(graphql`
        query {
            contentfulLayout(title: {eq: "Cinder"}) {
                id
                title
                description {
                    description
                }
                contentful_id,
                menu {
                    name
                    type
                    menuItems {
                        id
                        title
                        url
                    }
                },
                contentModule {
                    ... on Node {
                        id
                    }
                }
            }
        }
    `);
    const menus = data.contentfulLayout.menu;
    const contentModule = data.contentfulLayout.contentModule;

    return (
        <Layout menus={ menus }>
            <SEO title="Get Cinder" />
            <div class="flex justify-center">
                <div class="w-full max-w-xl rounded shadow-lg bg-white">
                    <div class="px-6 py-4">
                        <div class="font-bold text-xl mb-2">The Coldest Sunset</div>
                        <p class="text-gray-700 text-base">
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Voluptatibus quia, nulla! Maiores et perferendis eaque, exercitationem praesentium nihil.
                        </p>
                    </div>
                    <div class="px-6 pt-4 pb-2">
                        <span class="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">#photography</span>
                        <span class="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">#travel</span>
                        <span class="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">#winter</span>
                    </div>
                    <form class="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
                        <div class="mb-4">
                            <label class="block text-gray-700 text-sm font-bold mb-2" for="username">
                                Username
                            </label>
                            <input class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="username" type="text" placeholder="Username" />
                        </div>  
                    </form>
                </div>
            </div>
        </Layout>
    );
};

export default GetCinder;

